import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {path: 'accueil', loadChildren:()=>import('./accueil/accueil.module').then(m=>m.AccueilModule)},
  {path: 'activites', loadChildren:()=>import('./activites/activites.module').then(m=>m.ActivitesModule)},
  {path: 'apropos', loadChildren:()=>import('./apropos/apropos.module').then(m=>m.AproposModule)},
  {path: 'services', loadChildren:()=>import('./page-service/page-service.module').then(m=>m.PageServiceModule)},
  {path: 'blog', loadChildren:()=>import('./blog/blog.module').then(m=>m.BlogModule)},
  {path: 'contact', loadChildren: ()=>import('./contact/contact.module').then(m=>m.ContactModule)},
  {path: 'login', loadChildren:()=>import('./login/login.module').then(m=>m.LoginModule)},
  {path: 'inscription', loadChildren:()=>import('./inscription/inscription.module').then(m=>m.InscriptionModule)},

  {path: 'admin', loadChildren:()=>import('./admin/admin.module').then(m=>m.AdminModule)},


  {path: '**', redirectTo: 'accueil'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
