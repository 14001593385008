<footer class="px-8 flex w-full flex-row flex-wrap items-center justify-center gap-y-6 gap-x-12 border-t border-blue-gray-50 py-6 text-center md:justify-between">
  <p class="block font-sans text-base font-normal leading-relaxed text-blue-gray-900 antialiased">
    <a href="https://macroinfos.com/"> © 2023 TCIB, Design by MacroInfos</a>
  </p>
  <ul class="lg:flex flex-wrap items-center gap-y-2 gap-x-8 hidden ">
    <li>
      <a
        routerLink="/accueil" routerLinkActive="active"
        class="block font-sans text-base font-normal leading-relaxed text-blue-gray-900 antialiased transition-colors hover:text-pink-500 focus:text-pink-500"
      >
        Accueil
      </a>
    </li>
    <li>
      <a
      routerLink="/apropos" routerLinkActive="active"
        class="block font-sans text-base font-normal leading-relaxed text-blue-gray-900 antialiased transition-colors hover:text-pink-500 focus:text-pink-500"
      >
        A propos
      </a>
    </li>
    <li>
      <a
        routerLink="/activites" routerLinkActive="active"
        class="block font-sans text-base font-normal leading-relaxed text-blue-gray-900 antialiased transition-colors hover:text-pink-500 focus:text-pink-500"
      >
        Activités
      </a>
    </li>
    <li>
      <a
        routerLink="/services" routerLinkActive="active"
        class="block font-sans text-base font-normal leading-relaxed text-blue-gray-900 antialiased transition-colors hover:text-pink-500 focus:text-pink-500"
      >
        Services
      </a>
    </li>
    <li>
      <a
        routerLink="/blog" routerLinkActive="active"
        class="block font-sans text-base font-normal leading-relaxed text-blue-gray-900 antialiased transition-colors hover:text-pink-500 focus:text-pink-500"
      >
        Blogs
      </a>
    </li>
    <li>
      <a
        routerLink="/contact" routerLinkActive="active"
        class="block font-sans text-base font-normal leading-relaxed text-blue-gray-900 antialiased transition-colors hover:text-pink-500 focus:text-pink-500"
      >
        Contact
      </a>
    </li>
  </ul>
</footer>



