
<nav class="sticky inset-0 z-10 block h-max w-full max-w-full rounded-none border border-white/80 bg-white bg-opacity-80 py-2 px-4 text-white shadow-md backdrop-blur-2xl backdrop-saturate-200 lg:px-8 lg:py-4">
  <div class="flex items-center text-gray-900">
      <a href="#" class="mr-4 block cursor-pointer py-1.5 font-bold text-2xl  leading-relaxed text-teal-900 antialiased">
        TCIB
      </a>
      <ul class="ml-auto mr-8 hidden items-center gap-6 lg:flex">
        <li class="block p-1 font-sans text-sm font-normal leading-normal text-inherit antialiased">
          <a  routerLink="/accueil" routerLinkActive="active"  class="flex items-center uppercase hover:text-pink-500" >
            Accueil
          </a>
        </li>
        <li class="block p-1 font-sans text-sm font-normal leading-normal text-inherit antialiased">
          <a routerLink="/apropos" routerLinkActive="active"  class="flex items-center uppercase hover:text-pink-500">
            A propos
          </a>
        </li>
        <li class="block p-1 font-sans text-sm font-normal leading-normal text-inherit antialiased">
          <a routerLink="/activites" routerLinkActive="active"  class="flex items-center uppercase hover:text-pink-500">
            Activités
          </a>
        </li>
        <li class="block p-1 font-sans text-sm font-normal leading-normal text-inherit antialiased">
          <a routerLink="/services" routerLinkActive="active"  class="flex items-center uppercase hover:text-pink-500">
            Services
          </a>
        </li>
        <li class="block p-1 font-sans text-sm font-normal leading-normal text-inherit antialiased">
          <a routerLink="/blog" routerLinkActive="active"  class="flex items-center uppercase hover:text-pink-500">
            Blogs
          </a>
        </li>
        <li class="block p-1 font-sans text-sm font-normal leading-normal text-inherit antialiased">
          <a routerLink="/contact" routerLinkActive="active"  class="flex items-center uppercase hover:text-pink-500">
            Contact
          </a>
        </li>
      </ul>
      <button (click)="showMenu()" *ngIf="!smallMenu"  class="middle none relative ml-auto h-6 max-h-[40px] w-6 max-w-[40px] rounded-lg text-center font-sans text-xs font-medium uppercase hover:text-pink-500 text-blue-gray-500 transition-all hover:bg-transparent focus:bg-transparent active:bg-transparent disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none lg:hidden"
          >
          <span class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 transform">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </span>
        </button>
        <button (click)="showMenu()" *ngIf="smallMenu"  class="middle none relative ml-auto h-6 max-h-[40px] w-6 max-w-[40px] rounded-lg text-center font-sans text-xs font-medium uppercase hover:text-pink-500 text-blue-gray-500 transition-all hover:bg-transparent focus:bg-transparent active:bg-transparent disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none lg:hidden"
          >
          <span class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 transform text-lg px-2 bg-rose-700 text-white rounded-full">
            x
          </span>
        </button>
  </div>
  <div class="lg:hidden w-full basis-full text-black" *ngIf="smallMenu">
    <ul class="mt-2 mb-4 flex flex-col gap-2 pb-2">
      <li class="block p-1 font-sans text-sm font-normal leading-normal text-inherit antialiased">
        <a  routerLink="/accueil" routerLinkActive="active"  class="flex items-center uppercase hover:text-pink-500" >
          Accueil
        </a>
      </li>
      <li class="block p-1 font-sans text-sm font-normal leading-normal text-inherit antialiased">
        <a routerLink="/apropos" routerLinkActive="active"  class="flex items-center uppercase hover:text-pink-500">
          A propos
        </a>
      </li>
      <li class="block p-1 font-sans text-sm font-normal leading-normal text-inherit antialiased">
        <a routerLink="/activites" routerLinkActive="active"  class="flex items-center uppercase hover:text-pink-500">
          Activités
        </a>
      </li>
      <li class="block p-1 font-sans text-sm font-normal leading-normal text-inherit antialiased">
        <a routerLink="/services" routerLinkActive="active"  class="flex items-center uppercase hover:text-pink-500">
          Services
        </a>
      </li>
      <li class="block p-1 font-sans text-sm font-normal leading-normal text-inherit antialiased">
        <a routerLink="/blog" routerLinkActive="active"  class="flex items-center uppercase hover:text-pink-500">
          Blogs
        </a>
      </li>
      <li class="block p-1 font-sans text-sm font-normal leading-normal text-inherit antialiased">
        <a routerLink="/contact" routerLinkActive="active"  class="flex items-center uppercase hover:text-pink-500">
          Contact
        </a>
      </li>
    </ul>
  </div>








  <!-- <div class="lg:flex flex-row lg:justify-between lg:justify-items-center">
    <div class="flex items-center pb-2 px-4">
      <p routerLink="/accueil" class="text-3xl lg:text-5xl text-white font-bold">TCIB</p>
      <button (click)="showMenu()" *ngIf="!smallMenu"  class="lg:hidden inline-flex items-center justify-center text-white border h-10 w-10 ml-auto rounded-md outline-none focus:outline-none" >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
      </button>
      <button (click)="showMenu()" *ngIf="smallMenu" class="lg:hidden pt-2 inline-flex items-center justify-center text-white border h-10 w-10 ml-auto rounded-md outline-none focus:outline-none" >
            <p class="text-2xl items-center justify-center">x</p>
      </button>
    </div>
    <div class="hidden lg:flex flex-row lg:space-x-8 items-center">
      <div routerLink="/accueil" routerLinkActive="active"  class="py-2 px-4 rounded-md shadow-lg shadow-slate-500">
        <a routerLink="/accueil" routerLinkActive="active" class="text-xl text-white font-extralight  hover:text-slate-300 cursor-pointer">Accueil</a>
      </div>
      <div routerLink="/apropos" routerLinkActive="active" class="py-2 px-4 rounded-md shadow-lg shadow-slate-500">
        <a routerLink="/apropos" routerLinkActive="active" class="text-xl text-white font-extralight  hover:text-slate-300 cursor-pointer">A propos</a>
      </div>
      <div routerLink="/activites" routerLinkActive="active" class="py-2 px-4 rounded-md shadow-lg shadow-slate-500">
        <a routerLink="/activites" routerLinkActive="active"  class="text-xl text-white font-extralight  hover:text-slate-300 cursor-pointer">Activités</a>
      </div>
      <div routerLink="/services" routerLinkActive="active" class="py-2 px-4 rounded-md shadow-lg shadow-slate-500">
        <a routerLink="/services" routerLinkActive="active" class="text-xl text-white font-extralight  hover:text-slate-300 cursor-pointer">Services</a>
      </div>
      <div routerLink="/blog" routerLinkActive="active" class="py-2 px-4 rounded-md shadow-lg shadow-slate-500">
        <a routerLink="/blog" routerLinkActive="active" class="text-xl text-white font-extralight  hover:text-slate-300 cursor-pointer">Blogs</a>
      </div>
      <div routerLink="/contact" routerLinkActive="active" class="py-2 px-4 rounded-md shadow-lg shadow-slate-500">
        <a routerLink="/contact" routerLinkActive="active" class="text-xl text-white font-extralight  hover:text-slate-300 cursor-pointer">Contact</a>
      </div>
    </div>

    <div  class="lg:hidden flex-row items-center bancgroud" *ngIf="smallMenu">
      <div routerLink="/accueil" routerLinkActive="active"  class=" my-1 p-2 hover:bg-teal-50 hover:text-black cursor-pointer ">
        <a  class="text-xl font-extralight ">Accueil</a>
      </div>
      <div routerLink="/apropos" routerLinkActive="active" class="  my-1 p-2 hover:bg-teal-50 hover:text-black cursor-pointer ">
        <a  class="text-xl font-extralight">A propos</a>
      </div>
      <div  routerLink="/activites" routerLinkActive="active" class="  my-1 p-2 hover:bg-teal-50 hover:text-black cursor-pointer ">
        <a class="text-xl font-extralight">Activités</a>
      </div>
      <div routerLink="/services" routerLinkActive="active" class="  my-1 p-2 hover:bg-teal-50 hover:text-black cursor-pointer ">
        <a  class="text-xl font-extralight">Services</a>
      </div>
      <div routerLink="/blog" routerLinkActive="active" class="  my-1 p-2 hover:bg-teal-50 hover:text-black cursor-pointer ">
        <a  class="text-xl font-extralight">Blogs</a>
      </div>
      <div routerLink="/contact" routerLinkActive="active" class="  my-1 p-2 hover:bg-teal-50 hover:text-black cursor-pointer ">
        <a  class="text-xl font-extralight">Contact</a>
      </div>
    </div>
  </div> -->
</nav>



<!-- <nav class="sticky inset-0 z-10 block h-max w-full max-w-full rounded-none border border-white/80 bg-white bg-opacity-80 py-2 px-4 text-white shadow-md backdrop-blur-2xl backdrop-saturate-200 lg:px-8 lg:py-4">
  <div class="flex items-center text-gray-900">
    <a
      href="#"
      class="mr-4 block cursor-pointer py-1.5 font-sans text-base font-medium leading-relaxed text-inherit antialiased"
    >
      Material Tailwind
    </a>
    <ul class="ml-auto mr-8 hidden items-center gap-6 lg:flex">
      <li class="block p-1 font-sans text-sm font-normal leading-normal text-inherit antialiased">
        <a class="flex items-center" href="#">
          Pages
        </a>
      </li>
      <li class="block p-1 font-sans text-sm font-normal leading-normal text-inherit antialiased">
        <a class="flex items-center" href="#">
          Account
        </a>
      </li>
      <li class="block p-1 font-sans text-sm font-normal leading-normal text-inherit antialiased">
        <a class="flex items-center" href="#">
          Blocks
        </a>
      </li>
      <li class="block p-1 font-sans text-sm font-normal leading-normal text-inherit antialiased">
        <a class="flex items-center" href="#">
          Docs
        </a>
      </li>
    </ul>
    <button
      class="middle none center hidden rounded-lg bg-gradient-to-tr from-pink-600 to-pink-400 py-2 px-4 font-sans text-xs font-bold uppercase hover:text-pink-500 text-white shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none lg:inline-block"
      type="button"
      data-ripple-light="true"
    >
      <span>Buy Now</span>
    </button>
    <button
      class="middle none relative ml-auto h-6 max-h-[40px] w-6 max-w-[40px] rounded-lg text-center font-sans text-xs font-medium uppercase text-blue-gray-500 transition-all hover:bg-transparent focus:bg-transparent active:bg-transparent disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none lg:hidden"
      data-collapse-target="sticky-navar">
      <span class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 transform">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4 6h16M4 12h16M4 18h16"
          ></path>
        </svg>
      </span>
    </button>
  </div>

</nav>
<div class="mx-auto max-w-screen-md py-12">
  <div class="relative mb-12 flex flex-col overflow-hidden rounded-xl bg-white bg-clip-border text-gray-700 shadow-md">
    <img
      alt="nature"
      class="h-[32rem] w-full object-cover object-center"
      src="https://images.unsplash.com/photo-1485470733090-0aae1788d5af?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=2717&amp;q=80"
    />
  </div>
  <h2 class="mb-2 block font-sans text-4xl font-semibold leading-[1.3] tracking-normal text-blue-gray-900 antialiased">
    What is Material Tailwind
  </h2>
  <p class="block font-sans text-base font-normal leading-relaxed text-gray-700 antialiased">
    Can you help me out? you will get a lot of free exposure doing this can my
    website be in english?. There is too much white space do less with more,
    so that will be a conversation piece can you rework to make the pizza look
    more delicious other agencies charge much lesser can you make the blue
    bluer?. I think we need to start from scratch can my website be in
    english?, yet make it sexy i'll pay you in a week we don't need to pay
    upfront i hope you understand can you make it stand out more?. Make the
    font bigger can you help me out? you will get a lot of free exposure doing
    this that's going to be a chunk of change other agencies charge much
    lesser. Are you busy this weekend? I have a new project with a tight
    deadline that's going to be a chunk of change. There are more projects
    lined up charge extra the next time.
  </p>
</div> -->















<!-- <nav class="mt-0 fixed w-full z-10 top-0 lg:px-8 h-[68px] lg:h-[110px]  pt-2 lg:py-4 bancgroud shadow-sm shadow-teal-900">
  <div class="lg:flex flex-row lg:justify-between lg:justify-items-center">
    <div class="flex items-center pb-2 px-4">
      <p routerLink="/accueil" class="text-3xl lg:text-5xl text-white font-bold">TCIB</p>
      <button (click)="showMenu()" *ngIf="!smallMenu"  class="lg:hidden inline-flex items-center justify-center text-white border h-10 w-10 ml-auto rounded-md outline-none focus:outline-none" >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
      </button>
      <button (click)="showMenu()" *ngIf="smallMenu" class="lg:hidden pt-2 inline-flex items-center justify-center text-white border h-10 w-10 ml-auto rounded-md outline-none focus:outline-none" >
            <p class="text-2xl items-center justify-center">x</p>
      </button>
    </div>
    <div class="hidden lg:flex flex-row lg:space-x-8 items-center">
      <div routerLink="/accueil" routerLinkActive="active" class="py-2 px-4 rounded-md shadow-lg shadow-slate-500">
        <a routerLink="/accueil" routerLinkActive="active" class="text-xl text-white font-extralight  hover:text-slate-300 cursor-pointer">Accueil</a>
      </div>
      <div routerLink="/apropos" routerLinkActive="active" class="py-2 px-4 rounded-md shadow-lg shadow-slate-500">
        <a routerLink="/apropos" routerLinkActive="active" class="text-xl text-white font-extralight  hover:text-slate-300 cursor-pointer">A propos</a>
      </div>
      <div routerLink="/activites" routerLinkActive="active" class="py-2 px-4 rounded-md shadow-lg shadow-slate-500">
        <a routerLink="/activites" routerLinkActive="active"  class="text-xl text-white font-extralight  hover:text-slate-300 cursor-pointer">Activités</a>
      </div>
      <div routerLink="/services" routerLinkActive="active" class="py-2 px-4 rounded-md shadow-lg shadow-slate-500">
        <a routerLink="/services" routerLinkActive="active" class="text-xl text-white font-extralight  hover:text-slate-300 cursor-pointer">Services</a>
      </div>
      <div routerLink="/blog" routerLinkActive="active" class="py-2 px-4 rounded-md shadow-lg shadow-slate-500">
        <a routerLink="/blog" routerLinkActive="active" class="text-xl text-white font-extralight  hover:text-slate-300 cursor-pointer">Blogs</a>
      </div>
      <div routerLink="/contact" routerLinkActive="active" class="py-2 px-4 rounded-md shadow-lg shadow-slate-500">
        <a routerLink="/contact" routerLinkActive="active" class="text-xl text-white font-extralight  hover:text-slate-300 cursor-pointer">Contact</a>
      </div>
    </div>

    <div  class="lg:hidden flex-row items-center bancgroud" *ngIf="smallMenu">
      <div routerLink="/accueil" routerLinkActive="active"  class=" my-1 p-2 hover:bg-teal-50 hover:text-black cursor-pointer ">
        <a  class="text-xl font-extralight ">Accueil</a>
      </div>
      <div routerLink="/apropos" routerLinkActive="active" class="  my-1 p-2 hover:bg-teal-50 hover:text-black cursor-pointer ">
        <a  class="text-xl font-extralight">A propos</a>
      </div>
      <div  routerLink="/activites" routerLinkActive="active" class="  my-1 p-2 hover:bg-teal-50 hover:text-black cursor-pointer ">
        <a class="text-xl font-extralight">Activités</a>
      </div>
      <div routerLink="/services" routerLinkActive="active" class="  my-1 p-2 hover:bg-teal-50 hover:text-black cursor-pointer ">
        <a  class="text-xl font-extralight">Services</a>
      </div>
      <div routerLink="/blog" routerLinkActive="active" class="  my-1 p-2 hover:bg-teal-50 hover:text-black cursor-pointer ">
        <a  class="text-xl font-extralight">Blogs</a>
      </div>
      <div routerLink="/contact" routerLinkActive="active" class="  my-1 p-2 hover:bg-teal-50 hover:text-black cursor-pointer ">
        <a  class="text-xl font-extralight">Contact</a>
      </div>
    </div>
  </div>
</nav> -->







