import { Injectable } from '@angular/core';

@Injectable()

export class AppService {

  constructor(){}

  handleUser(dataUser: string){
   localStorage.setItem('user', JSON.stringify(dataUser));
  }

  getDataUser(){
    return JSON.parse(localStorage.getItem('user')!);
  }

  removeDataUser() {
    localStorage.removeItem('user');
  }

}
