<div class="sticky z-10 top-0 h-16 border-b bg-white lg:py-2.5 items-center">
  <div class="pl-6 pr-2 flex items-center justify-between space-x-4 2xl:container">
      <h5 hidden class="text-2xl text-gray-600 font-medium lg:block">Administration</h5>
      <button class="w-12 h-16 -mr-2 border-r lg:hidden">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 my-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
      </button>
      <div class="flex space-x-4">
         <div class="px-6 -mx-6 flex justify-between items-center border-t bg-rose-700 hover:bg-rose-900">
            <button class="px-4 py-3 flex items-center space-x-4 rounded-md text-white group" (click)="signOut()">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                </svg>
                <span class="text-white" style="padding-left: 3%;">Déconnexion</span>
            </button>
        </div>
      </div>
  </div>
</div>



